//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import svgSymbol from '~/components/svg-symbol'

export default {
	layout: 'empty',

	components:
	{
		svgSymbol
	},

	data()
	{
		return {
			email: '',
			password: '',
			loginError: null,
		}
	},

	computed:
	{
		...mapGetters(['lang', 'langShort', 'allLanguages', 'windowWidth']),

		formClass()
		{
			return  {
				'login-error': this.loginError,
				'animate-shake': this.loginError
			}
		},
	},

	beforeMount()
	{
		if ( this.$auth.loggedIn )
		{
			this.doLogout()
		}
	},

	methods:
	{
		onSubmit(event)
		{
			consola.debug('onSubmit');
			event.preventDefault();
			event.stopPropagation();
			this.loginError = false;

			this.$auth.login({
				data: {
					email: this.email,
					password: this.password,
				}
			})
			.then( (resp) =>
			{
				consola.debug('resp: ', resp);
				if (this.$route.query && this.$route.query.redirect)
				{
					consola.debug('redirecting to ', this.$route.query.redirect);
					this.$router.push(this.$route.query.redirect)
				}
				else
				{
					this.$router.push({ path: this.lang+'/corporate', query: {  } })
				}
			} )
			.catch( error =>
			{
				consola.error('login error: ', error);
				this.loginError = true;
			})
		},

		doLogout()
		{
			this.$auth.logout()
				.then( resp =>
				{
					location.reload();
				})
				.catch( error =>
				{
					consola.error(`${__filename}[197] error: `, error)
				})
		}
	}
}
